const GalleryData = [
    {
        id: 1,
        cover: require("../../Assets/Gallery/gallery1.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 2,
        cover: require("../../Assets/Gallery/gallery2.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 3,
        cover: require("../../Assets/Gallery/gallery3.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 4,
        cover: require("../../Assets/Gallery/gallery4.jpeg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 5,
        cover: require("../../Assets/Gallery/gallery5.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 6,
        cover: require("../../Assets/Gallery/gallery6.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 7,
        cover: require("../../Assets/Gallery/gallery7.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 8,
        cover: require("../../Assets/Gallery/gallery8.avif"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 9,
        cover: require("../../Assets/Gallery/gallery9.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 10,
        cover: require("../../Assets/Gallery/gallery10.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 11,
        cover: require("../../Assets/Gallery/gallery11.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 12,
        cover: require("../../Assets/Gallery/gallery12.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
    {
        id: 13,
        cover: require("../../Assets/Gallery/gallery13.jpg"),
        alttag: "Sobha 106 Gurgaon",
    },
   
]

export default GalleryData;